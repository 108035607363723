import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

const ManagementButtons = ({ approvalFlg, loginUser, handleDetailClick }) => {
    const buttonStyle = { margin: 1 };

    const renderRegisterButton = approvalFlg === false;
    const renderApproveButton = approvalFlg === "0" && [3, 4].includes(loginUser.authorityLevel);
    const renderBackButton = approvalFlg === "0";

    return (
        <Box mt={2} textAlign="center">
            {renderRegisterButton && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDetailClick('register')}
                    sx={buttonStyle}
                >
                    登録
                </Button>
            )}
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleDetailClick('edit')}
                sx={buttonStyle}
            >
                編集
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleDetailClick('delete')}
                sx={buttonStyle}
            >
                削除
            </Button>
            {renderApproveButton && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDetailClick('approve')}
                    sx={buttonStyle}
                >
                    承認
                </Button>
            )}
            {renderBackButton && (
                <Button
                    component={Link}
                    to="/unapproved"
                    variant="contained"
                    color="secondary"
                    sx={buttonStyle}
                >
                    戻る
                </Button>
            )}
        </Box>
    );
};

export default ManagementButtons;
