import React from 'react';
import TcuSettingModal from '../modals/tcu-setting-modal';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function TcuSetting() {
    const niMHToText = (status) => {
        switch (status) {
            case 0:
                return 'なし';
            case 1:
                return 'あり';
            default:
                return '不明';
        }
    };

    return (
        <>
            <Header current="車載器設定" />
            <CommonManagement
                endpoint={["/tcu-settings"]}
                dropdownOptions={[
                    { value: '4-digitFrameNumber', label: '車台番号(下4桁)' },
                    { value: 'frameNumber', label: '車台番号(全一致)' },
                ]}
                tableHeaders={[
                    '車台番号', 
                    '位置情報送信間隔(エンジンON)', 
                    '位置情報送信間隔(エンジンOFF)',
                    'バッテリー電圧低下異常の閾値',
                    'バッテリー電圧急低下異常の閾値',
                    'バッテリー電圧急低下異常の時間',
                    '外部電源電圧低下異常閾値',
                    '衝撃検知閾値',
                    'Ni-MH使用'
                ]}
                tableRowData={(tcu) => [
                    tcu.frame_number,
                    tcu.ig_on_interval,
                    tcu.ig_off_interval,
                    tcu.low_battery_voltage_threshold,
                    tcu.sudden_battery_drop_threshold,
                    tcu.sudden_battery_drop_time,
                    tcu.low_external_power_threshold,
                    tcu.shock_detected_threshold,
                    niMHToText(tcu.ni_mh_req)
                ]}
                DetailModal={TcuSettingModal}
                approvalFlg={false}
                buttonType="setting"
                option="tcu-setting"
            />
        </>
    );
}

export default TcuSetting;


