import React from 'react';
import OtaModal from '../modals/ota-modal';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function OtaManagement() {
    return (
        <>
            <Header current="OTA設定" />
            <CommonManagement
                endpoint={["/tcu"]}
                dropdownOptions={[
                    { value: '4-digitFrameNumber', label: '車台番号(下4桁)' },
                    { value: 'frameNumber', label: '車台番号(全一致)' },
                    { value: 'imei', label: 'IMEI番号' },
                ]}
                tableHeaders={[
                    '車台番号', 
                    '車載器のIMEI番号', 
                ]}
                tableRowData={(tcu) => [
                    tcu.frame_number || '未登録',
                    tcu.imei || '未登録',
                ]}
                DetailModal={OtaModal}
                approvalFlg={false}
                buttonType="setting"
                option="ota"
            />
        </>
    );
}

export default OtaManagement;


