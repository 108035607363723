import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './auth/AuthProvider';
import {  ThemeProvider } from '@mui/material/styles';
import theme from "./theme.js";
import { Amplify } from "aws-amplify";
import { LoadScript } from '@react-google-maps/api';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'ap-northeast-1_GpVIX5SeF',
      userPoolClientId: '2q3dst93onaaqq740qstce2db8',
      loginWith: {
        oauth: {
          domain: 'https://eng-cut.auth.ap-northeast-1.amazoncognito.com',
          scopes: ['openid', 'email', 'profile', "aws.cognito.signin.user.admin"],
          responseType: 'code',
        }
      }
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <LoadScript googleMapsApiKey="AIzaSyAoxy_RA2nQr4Uu3q0DS7mmgzJSSHNp0uU">
            <App />
          </LoadScript>
        </AuthProvider>
      </ThemeProvider>
  </React.StrictMode>
);
