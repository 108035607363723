import React, { useState } from 'react';
import { Box, TextField, Button, Alert } from '@mui/material';
import { resetPassword } from 'aws-amplify/auth';
import axios from 'axios';

function ResetPasswordRequestForm({ mailAddress, onClose, setStep }) {
    const [username, setUsername] = useState(mailAddress);
    const [alert, setAlert] = useState({ show: false, message: '' });

    // パスワードリセットの次のステップを処理
    const handleResetPasswordNextSteps = (output) => {
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                setStep("confirm");
                break;
            case 'DONE':
                onClose();
                break;
            default:
                console.log('Invalid reset password step:', nextStep.resetPasswordStep);
        }
    }

    // パスワードリセット処理
    const handleResetPassword = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/auth/${username}`);
            if (response.data.message === 'user not found') {
                setAlert({ show: true, message: 'メールアドレスが正しくありません' });
                return;
            }
            const output = await resetPassword({ username });
            handleResetPasswordNextSteps(output);
        } catch (error) {
            console.log('error resetting password:', error);
        }
    }
    

    return (
        <>
            <TextField
                label="メールアドレス"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                margin="normal"
            />
            {alert.show && (
                <Alert severity="error" onClose={() => setAlert({ show: false })}>
                    {alert.message}
                </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleResetPassword}>
                    リセットコードを送信
                </Button>
            </Box>
        </>
    );
}

export default ResetPasswordRequestForm;