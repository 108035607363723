import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Typography, Breadcrumbs, Button } from '@mui/material';
import { AuthContext } from "../auth/AuthProvider";
import { signOut } from 'aws-amplify/auth';

const Header = ({ current }) => {
    const { logout, auth } = useContext(AuthContext);
    const user = auth.user;
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut();
            logout();
            navigate('/login');
        } catch (error) {
            console.error('error signing out: ', error);
        }
    };

    if(auth.isLoading) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" padding={1} boxShadow={1}>
            <Box 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center"
                paddingBottom={1} 
                borderBottom="1px solid #ccc"
            >
                <Box flexGrow={1}> 
                    <Typography variant="h6">エンジン停止システム</Typography>
                </Box>
                <Typography variant="h6" component="span">
                    {user?.name}
                </Typography>
                <Button variant="outlined" color="primary" sx={{ ml: 2 }} onClick={handleLogout}>
                        ログアウト
                </Button>
            </Box>
            <Box marginTop={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/" style={{ textDecoration: 'none', color: '#1976d2' }}>
                        ホーム
                    </Link>
                    {current !== 'ホーム' && (
                        <Typography color="textPrimary">{current}</Typography>
                    )}
                </Breadcrumbs>
            </Box>
        </Box>
    );
}

export default Header;
