import React from 'react';
import AdminModal from '../modals/admin-modal';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function CarManagement() {
    const authorityLevelToText = (status) => {
        switch (status) {
            case 1:
                return '代理店一般ユーザー';
            case 2:
                return '代理店管理ユーザー';
            case 3:
                return 'ネクスト所属';
            case 4:
                return 'サージュ所属';
            default:
                return '不明';
        }
    };
    return (
        <>
            <Header current="管理者管理" />
            <CommonManagement
                endpoint={["/admins"]}
                dropdownOptions={[{ value: 'adminName', label: '氏名' }]}
                tableHeaders={['氏名', '電話番号', 'メールアドレス', '権限レベル', '所属代理店']}
                tableRowData={(admin) => [
                    admin.admin_name,
                    admin.phone_number || '未登録',
                    admin.mail_address || '未登録',
                    authorityLevelToText(admin.authority_level),
                    (admin.authority_level === 1 || admin.authority_level === 2) ? admin.renterName : '',
                ]}
                DetailModal={AdminModal}
                approvalFlg={false}
                buttonType="management"
            />
        </>
    );
}


export default CarManagement;
