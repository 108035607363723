import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab, Alert } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import CarForm from '../../components/forms/car-form';
import UserForm from '../../components/forms/user-form';
import transformCarData from '../../utils/forms/transform-car-data';
import transformUserData from '../../utils/forms/transform-user-data';

const UnapprovedModal = ({ item, onClose }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [alert, setAlert] = useState({ show: false, message: '' });
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    var [carYearYearString, carYearMonthString] = item?.car_year?.split('-') || ['', ''];
    carYearMonthString = carYearMonthString ? parseInt(carYearMonthString, 10) : '';
    const methods = useForm({
        defaultValues: {
            userName: item ? item.user_name : '',
            userKana: item ? item.user_kana : '',
            phoneNumber: item ? item.phone_number : '',
            mailAddress: item ? item.mail_address : '',
            zipCode: item ? item.zip_code : '',
            address: item ? item.address : '',
            address2: item ? item.address2 : '',
            renterCode: '',
            carMaker: item ? item.car_maker : '',
            carName: item ? item.car_name : '',
            carYear_gengou: false,
            carYear_year: carYearYearString,
            carYear_month: carYearMonthString,
            frameNumber: item ? item.frame_number : '',
            carNumber: item ? item.car_number : '',
            carInspectionDate_gengou: false,
            carInspectionDate_year: item ? new Date(item.car_inspection_date).getFullYear() : '',
            carInspectionDate_month: item ? new Date(item.car_inspection_date).getMonth() + 1 : '',
            carInspectionDate_day: item ? new Date(item.car_inspection_date).getDate() : '',
            carColor: item ? item.car_color : '',
            tcuCode: item ? item.status.tcu_code : null,
        }
    });
    const { handleSubmit } = methods;

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const onSubmit = async () => {
        const data = methods.getValues();
        if (!data.carName || !data.frameNumber || !data.renterCode)
            return setAlert({ show: true, message: '車両情報を入力してください' });
        if (!data.userName || !data.userKana || !data.phoneNumber || !data.mailAddress || !data.zipCode || !data.address)
            return setAlert({ show: true, message: '賃借人情報を入力してください' });

        try {
            const carData = transformCarData(data);
            const userData = transformUserData(data);
            userData.frameNumber = carData.frameNumber;
            userData.renterCode = carData.renterCode;
            if (!item) {
                carData.approvalFlg = '0';
                userData.approvalFlg = '0';
            }

            // 車両情報のエントリー/編集            
            const carResponse = item 
                ? await axios.put(`${apiUrl}/cars/${item.car_code}`, carData) // 編集
                : await axios.post(`${apiUrl}/cars`, carData); // エントリー

            if (carResponse.data.message === 'frame number already exists') {
                setAlert({ show: true, message: 'この車台番号は既に登録されています。' });
                return;
            }
            
            // 賃借人情報のエントリー/編集
            item ? await axios.put(`${apiUrl}/users/${item.user_code}`, userData) // 編集
                : await axios.post(`${apiUrl}/users`, userData); // エントリー

            navigate('/unapproved');
        } catch (error) {
            setAlert({ show: true, message: '送信エラーが発生しました。' });
            console.error('Error during submission:', error);
        }
    };

    return (
        <Dialog open onClose={() => onClose(false)} fullWidth>
            <DialogTitle>車両・賃借人エントリー</DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
                            <Tab label="車両情報" />
                            <Tab label="賃借人情報" />
                        </Tabs>

                        {tabIndex === 0 && <CarForm item={item} />}
                        {tabIndex === 1 && <UserForm/>}

                        {alert.show && (
                            <Alert severity="error" onClose={() => setAlert({ show: false })}>
                                {alert.message}
                            </Alert>
                        )}
                    </Box>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                    {item ? '編集' : 'エントリー'}
                </Button>
                <Button onClick={() => onClose(false)}>閉じる</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnapprovedModal;
