import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { TextField, MenuItem, Button, FormControl, InputLabel, Select, Paper } from '@mui/material';
import { AuthContext } from '../auth/AuthProvider';
import connectCarWithUser from '../utils/connect-car-with-user';

const SearchComponent = ({ endpoint, dropdownOptions, onDataFetched, approvalFlg, setSelectedItem, engCut, setIsLoading }) => {
    const { auth } = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    const searchTargetList = [
        { target: 'frameNumber', endpoint: '/cars' },
        { target: '4-digitFrameNumber', endpoint: '/cars' },
        { target: 'userName', endpoint: '/users' },
        { target: 'userKana', endpoint: '/users' },
        { target: 'imei', endpoint: '/tcu' },
        { target: 'adminName', endpoint: '/admins' },
        { target: 'renterName', endpoint: '/renters' }
    ]

    useEffect(() => {
        if (dropdownOptions.length > 0) {
            setDropdownValue(dropdownOptions[0].value);
        }
    }, [dropdownOptions]);

    const fetchData = async (params) => {
        try {
            setIsLoading(true);
            let response;
            const searchEndpoint = searchTargetList.find(item => item.target === params.target).endpoint;
            for (const ep of endpoint) {
                if (searchEndpoint === ep) {
                    response = await axios.get(`${apiUrl}${ep}`, { params });
                    break; 
                }
            }
            if (response.data.length === 0) {
                onDataFetched([]);
                return;
            }

            let data = response.data;

            // 未承認一覧の場合、車両と賃借人をつなげる
            let carList, userList;
            if (endpoint.length > 1) {
                params = { target: null, value: null, loginUser: auth.user, approvalFlg };
                if (data[0].car_name) {
                    carList = data;
                    const userResponse = await axios.get(`${apiUrl}/users`, { params });
                    userList = userResponse.data;
                } else if (data[0].user_name) {
                    const carResponse = await axios.get(`${apiUrl}/cars`, { params });
                    carList = carResponse.data;
                    userList = data;
                }
                data = connectCarWithUser(carList, userList);
            }
            setSelectedItem([]);
            onDataFetched(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        };
    };

    const handleSearch = () => {
        fetchData({ target: dropdownValue, value: searchValue, loginUser: auth.user, approvalFlg });
    };

    const handleReset = () => {
        if (engCut) {
            setSelectedItem([]);
            onDataFetched([]);
        } else {
            fetchData({ target: null, value: null, loginUser: auth.user, approvalFlg });
        }
    };

    return (
        <Paper
            display="flex" 
            alignItems="center"
            sx={{ border: '1px solid #ddd', maxWidth: 600, margin: '30px auto', padding: 2 }}
        >
            <TextField
                label="検索"
                variant="outlined"
                size="small"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                sx={{ flex: 2, marginRight: 2, }}
            />
            <FormControl size="small" sx={{ flex: 1, marginRight: 2 }}>
                <InputLabel>選択</InputLabel>
                <Select
                    value={dropdownValue}
                    onChange={(e) => setDropdownValue(e.target.value)}
                    label="選択"
                >
                    {dropdownOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSearch} sx={{ marginRight: 2 }}>
                検索
            </Button>
            <Button variant="contained" color="primary" onClick={handleReset}>
                リセット
            </Button>
        </Paper>
    );
};

export default SearchComponent;
