import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography, CircularProgress } from '@mui/material';

const DashboardItems = ({ title, count, isLoading }) => {
    const navigate = useNavigate();

    const link = {
        '車両数': '/car-management',
        '賃借人数': '/user-management',
        '未承認車両数': '/unapproved-cars',
        '未承認賃借人数': '/unapproved-users',
        'エンジンカットON': '/car-management?engCutOn=true',
    }

    const handleClick = () => {
        navigate(link[title]);
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={2.4} key={title}>
            <Paper 
                elevation={3} 
                sx={{ 
                    padding: 2, 
                    textAlign: 'center', 
                    backgroundColor: '#f8f8f8',
                    cursor: 'pointer', // カーソルをポインターに設定
                    transition: 'background-color 0.3s ease', // 背景色のトランジション効果
                    '&:hover': {
                        backgroundColor: '#e0e0e0', // ホバー時の背景色
                    },
                }}
                onClick={() => handleClick(title)}    
            >
                <Typography variant="h6" sx={{ marginBottom: 1, color: '#555' }}>{title}</Typography>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>{count}</Typography>
                )}
            </Paper>
        </Grid>
    );
};

export default DashboardItems;