import React from 'react';
import CommonManagement from '../../components/common-management';
import Header from '../../components/header';

function SimManagement() {
    const simStatusToText = (status) => {
        switch (status) {
            case 0:
                return '休止';
            case 1:
                return '稼働';
            case 2:
                return '解約';
            default:
                return '不明';
        }
    };

    return (
        <>
            <Header current="SIM設定" />
            <CommonManagement
                endpoint={["/tcu"]}
                dropdownOptions={[
                    { value: 'frameNumber', label: '車台番号(下4桁)' },
                    { value: 'imei', label: 'IMEI番号' },
                ]}
                tableHeaders={[
                    'IMEI番号', 
                    '取付車両の車台番号', 
                    'SIMの状態',
                ]}
                tableRowData={(tcu) => [
                    tcu.imei,
                    tcu.frame_number || '未登録',
                    simStatusToText(tcu.sim_status),
                ]}
                DetailModal={false}
                approvalFlg={false}
                buttonType="sim"
            />
        </>
    );
}

export default SimManagement;


