import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TableSortLabel, CircularProgress } from '@mui/material';

function ResultsTable({ displayedList=[], selectedItem, setSelectedItem, tableHeaders, tableRowData, option, isLoading }) {
    const [sortBy, setSortBy] = useState(null); // 現在のソートキー
    const [sortDirection, setSortDirection] = useState('asc'); // ソートの方向

    const getItemCode = (item) => {
        if (item.car_name) return item.car_code; // 車両
        if (item.user_name) return item.user_code; // 賃借人
        if (item.admin_code) return item.admin_code; // 管理者
        if (item.renter_name) return item.renter_code; // 代理店
        if (item.tcu_code) return item.tcu_code; // TCU
        return item.code;
    };

    // ソートを行う関数
    const handleSort = (header) => {
        const isAsc = sortBy === header && sortDirection === 'asc';
        setSortBy(header);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    // データをソート
    const sortedList = [...displayedList].sort((a, b) => {
        if (!sortBy) return 0; // ソートキーが設定されていない場合
        const aValue = tableRowData(a)[tableHeaders.indexOf(sortBy)];
        const bValue = tableRowData(b)[tableHeaders.indexOf(sortBy)];
        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        if (sortDirection === 'asc') {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
            return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        }
    });

    // チェックボックスの選択状態を変更
    const handleCheckboxChange = (item) => {
        setSelectedItem(prevSelectedItems => {
            const isSelected = prevSelectedItems.some(selected => getItemCode(selected) === getItemCode(item));
            if (option === "engCut" || option === "tcu-setting") {
                return isSelected ? [] : [item];
            } else {
                return isSelected
                    ? prevSelectedItems.filter(selected => getItemCode(selected) !== getItemCode(item))
                    : [...prevSelectedItems, item];
            }
        });
    };

    const handleSelectAllChange = () => {
        setSelectedItem(selectedItem.length === displayedList.length ? [] : displayedList);
    };

    return (
        <TableContainer component={Paper} sx={{ marginY: 1 }}>
            <Table>
                <TableHead sx={{ backgroundColor: '#e8e6e6' }}>
                    <TableRow>
                        <TableCell padding="checkbox">
                            {option !== "engCut" && option !== "tcu-setting" &&
                                <Checkbox
                                    onChange={handleSelectAllChange}
                                    checked={displayedList.length !== 0 && selectedItem.length === displayedList.length}
                                />
                            }
                        </TableCell>
                        {tableHeaders.map((header, index) => (
                            <TableCell key={index}>
                                <TableSortLabel
                                    active={sortBy === header}
                                    direction={sortBy === header ? sortDirection : 'asc'}
                                    onClick={() => handleSort(header)}
                                >
                                    {header}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <TableCell colSpan={tableHeaders.length + 1} align="center">
                            <CircularProgress />
                        </TableCell>
                    ) : sortedList.length === 0 ? (
                        <TableCell colSpan={tableHeaders.length + 1} align="center"> 
                            該当なし 
                        </TableCell>
                    ) : (
                        sortedList.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedItem.some(selected => getItemCode(selected) === getItemCode(item))}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                </TableCell>
                                {tableRowData(item).map((data, index) => (
                                    <TableCell key={index}>{data ? data : '未登録'}</TableCell>
                                ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ResultsTable;
