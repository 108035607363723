import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import axios from 'axios';

function OtaToggleComponent({ items, setAlert, onComplete, isModal=false }) {
    const defaultOtaEnabled = items.length === 1 ? items[0].ota_req : 2;
    const [otaEnabled, setOtaEnabled] = useState(defaultOtaEnabled); 
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleOtaSwitchChange = async () => {
        const itemCodeList = items.map(item => item.tcu_code);
        try {
            await axios.put(`${apiUrl}/ota-file?codes=${itemCodeList}`, { otaReq: otaEnabled });
            setAlert({ show: true, message: 'OTA設定を更新しました', severity: 'success' });
            if (onComplete) onComplete(); // 完了後に親コンポーネントに通知
        } catch (error) {
            console.error('Error updating OTA setting:', error);
            setAlert({ show: true, message: 'OTA設定の更新に失敗しました', severity: 'error' });
        }
    };

    return (
        <Box display="flex" flexDirection={isModal ? "column" : "row"} justifyContent="center" alignItems="center">
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Button
                    onClick={() => setOtaEnabled(1)}
                    variant={otaEnabled ? (otaEnabled === 1 ? "contained" : "outlined") : "outlined"}
                    color="primary"
                    sx={{ marginRight: 2 }}
                >
                    有効
                </Button>
                <Button
                    onClick={() => setOtaEnabled(0)}
                    variant={otaEnabled ? "outlined" : "contained"}
                    color="primary"
                >
                    無効
                </Button>
            </Box>
            {isModal && (
                <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleOtaSwitchChange}>
                    確定
                </Button>
            )}
            {!isModal && (
                <Button variant="contained" color="primary" sx={{ marginLeft: 2 }} onClick={handleOtaSwitchChange}>
                    確定
                </Button>
            )}
        </Box>
    );
}

export default OtaToggleComponent;
