import React, { useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import ResetPasswordRequestForm from '../components/auth/reset-password-request';
import ConfirmPasswordResetForm from '../components/auth/confirm-password-request';


function ForgotPasswordModal({ onClose, mailAddress }) {
    const [step, setStep] = useState("reset"); 

    return (
        <Modal open onClose={onClose} >
            <Box sx={{ ...modalStyle }}>
                <Typography variant="h6" component="h2">
                    パスワードリセット
                </Typography>
                {step === "reset" ? (
                    <ResetPasswordRequestForm mailAddress={mailAddress} onClose={onClose} setStep={setStep} />
                ) : (
                    <ConfirmPasswordResetForm username={mailAddress} onClose={onClose} />
                )}
            </Box>
        </Modal>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
};

export default ForgotPasswordModal;
