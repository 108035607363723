import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { confirmResetPassword } from 'aws-amplify/auth';
import { Box, TextField, Button, Alert } from '@mui/material';

function ConfirmPasswordResetForm({ username, onClose }) {
    const [alert, setAlert] = useState({ show: false, message: '' });
    const { control, handleSubmit, formState: { errors } } = useForm();
    
    // 新しいパスワードを設定
    const handleConfirmResetPassword = async (data) => {
        const { confirmationCode, newPassword, confirmPassword } = data;
        if (newPassword !== confirmPassword) {
            setAlert({ show: true, message: 'パスワードが一致しません' });
            return;
        }
        try {
            await confirmResetPassword({ username, confirmationCode, newPassword });
            console.log('パスワードが正常にリセットされました。');
            onClose();
        } catch (error) {
            if (error.message.startsWith('Invalid verification code provided')) {
                setAlert({ show: true, message: '確認コードが正しくありません' });
            } else {
                console.log('error confirming password reset:', error);
            }
        }
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit(handleConfirmResetPassword)}>
                <Controller
                    name="confirmationCode"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="確認コード"
                            fullWidth
                            size="small"
                            margin="normal"
                            required
                        />
                    )}
                />
                <Controller
                    name="newPassword"
                    control={control}
                    rules={{
                        required: '入力してください',
                        minLength: { value: 8, message: '8文字以上で入力してください' },
                        maxLength: { value: 100, message: '100文字以内で入力してください' },
                        pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]*$/,
                            message: 'パスワードは、少なくとも1文字の小文字、大文字、特殊文字、数字を含める必要があります'
                        }
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="新しいパスワード"
                            fullWidth
                            size="small"
                            margin="normal"
                            type="password"
                            error={!!errors.newPassword}
                            helperText={errors.newPassword ? errors.newPassword.message : ''}
                            required
                        />
                    )}
                />
                <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                        required: '入力してください',
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="新しいパスワード（確認用）"
                            fullWidth
                            size="small"
                            margin="normal"
                            type="password"
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                            required
                        />
                    )}
                />
            </Box>
            {alert.show && (
                <Alert severity="error" onClose={() => setAlert({ show: false })}>
                    {alert.message}
                </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleSubmit(handleConfirmResetPassword)}>
                    パスワードをリセット
                </Button>
            </Box>
        </>
    );
}

export default ConfirmPasswordResetForm;
