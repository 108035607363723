import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import CarForm from '../../components/forms/car-form';
import transformCarData from '../../utils/forms/transform-car-data';

const CarModal = ({ item, onClose }) => {
    const car = item;
    const [alert, setAlert] = useState({ show: false, message: '' });
    const apiUrl = process.env.REACT_APP_API_URL;

    var [carYearYearString, carYearMonthString] = car?.car_year?.split('-') || ['', ''];
    carYearMonthString = carYearMonthString ? parseInt(carYearMonthString, 10) : '';
    const methods = useForm({
        defaultValues: {
            carMaker: car ? car.car_maker : '',
            carName: car ? car.car_name : '',
            carYear_gengou: false,
            carYear_year: carYearYearString,
            carYear_month: carYearMonthString,
            frameNumber: car ? car.frame_number : '',
            carNumber: car ? car.car_number : '',
            carInspectionDate_gengou: false,
            carInspectionDate_year: car ? new Date(car.car_inspection_date).getFullYear() : '',
            carInspectionDate_month: car ? new Date(car.car_inspection_date).getMonth() + 1 : '',
            carInspectionDate_day: car ? new Date(car.car_inspection_date).getDate() : '',
            carColor: car ? car.car_color : '',
            tcuCode: car ? car.status.tcu_code : null,
            renterCode: car ? car.renter_code : null,
        }
    });
    const { handleSubmit } = methods;

    const onSubmit = async () => {
        const carData = transformCarData(methods.getValues());
        try {
            const response = await axios.put(`${apiUrl}/cars/${car.car_code}`, carData)

            if (response.data.message === 'frame number already exists') {
                setAlert({ show: true, message: 'この車台番号は既に登録されています。' });
            } else {
                onClose();
            }
        } catch (error) {
            console.error('Error during car submission:', error);
        }
    };

    return (
        <Dialog open onClose={() => onClose(false)} fullWidth>
            <DialogTitle sx={{ mx: 2 }}>車両{car ? '編集' : 'エントリー'}</DialogTitle>
            <DialogContent sx={{ mx: 2 }}>
                <FormProvider {...methods}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <CarForm car={car} />
                        {alert.show && (
                            <Alert severity="error" onClose={() => setAlert({ show: false })}>
                                {alert.message}
                            </Alert>
                        )}
                    </Box>
                </FormProvider>
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>                
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                    {car ? '編集' : 'エントリー'}
                </Button>
                <Button onClick={() => onClose(false)}>閉じる</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CarModal;
