import './App.css';
import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import Login from './auth/login';
import NewPasswordForm from './auth/new-password-form';
import Home from './pages/home';
import EngCutManagement from './pages/managements/eng-cut-management';
import UnapprovedModal from './pages/modals/unapproved-modal';
import UnapprovedManagement from './pages/managements/unapproved-management';
import CarManagement from './pages/managements/car-management';
import UserManagement from './pages/managements/user-management';
import TcuManagement from './pages/managements/tcu-management';
import AdminManagement from './pages/managements/admin-management';
import RenterManagement from './pages/managements/renter-management';
import TcuSetting from './pages/managements/tcu-setting-management';
import OtaManagement from './pages/managements/ota-management';
import SimManagement from './pages/managements/sim-management';
import { AuthContext } from "./auth/AuthProvider";
import Sidebar from './components/sidebar';

function App() {
  const { auth } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <AppContent auth={auth}/>
    </BrowserRouter>
  );
}

// BrowserRouter内でのみ使用するコンポーネント
function AppContent({ auth }) {
  const location = useLocation(); // useLocationをここで使用
  const navigate = useNavigate();

  const ProtectedRoute = ({ children }) => {  
    if (auth.isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress/>
        </Box>
      );
    }
    if (!auth.user) {
      // ログインしていない場合はログインページにリダイレクト
      return <Navigate to="/login" />;
    }

    // ログインしている場合は子コンポーネントを表示
    return children;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* サイドバーとヘッダーの表示を条件付きで行う */}
      {location.pathname !== '/login' && location.pathname !== '/new-password' &&
        <Sidebar />
      }
      <Box sx={{ flexGrow: 1}}>
        <Routes>
          {/* ログインページ */}
          <Route path="/login" element={auth.user ? <Home /> : <Login />} />
          <Route path="/new-password" element={<NewPasswordForm />} />

          {/* 保護されたルート */}
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/eng-cut-search" element={<ProtectedRoute><EngCutManagement /></ProtectedRoute>} />
          <Route path="/entry" element={<ProtectedRoute><UnapprovedModal onClose={() => {navigate('/home');}}/></ProtectedRoute>} />
          <Route path="/unapproved" element={<ProtectedRoute><UnapprovedManagement /></ProtectedRoute>} />
          <Route path="/car-management" element={<ProtectedRoute><CarManagement /></ProtectedRoute>} />
          <Route path="/user-management" element={<ProtectedRoute><UserManagement /></ProtectedRoute>} />
          <Route path="/tcu-management" element={<ProtectedRoute><TcuManagement /></ProtectedRoute>} />
          <Route path="/admin-management" element={<ProtectedRoute><AdminManagement /></ProtectedRoute>} />
          <Route path="/renter-management" element={<ProtectedRoute><RenterManagement /></ProtectedRoute>} />
          <Route path="/agency-management" element={<ProtectedRoute><RenterManagement /></ProtectedRoute>} />
          <Route path="/tcu-setting" element={<ProtectedRoute><TcuSetting /></ProtectedRoute>} />
          <Route path="/ota-search" element={<ProtectedRoute><OtaManagement /></ProtectedRoute>} />
          <Route path="/sim-management" element={<ProtectedRoute><SimManagement /></ProtectedRoute>} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
